<template>
  <div id="power-bi" class="page page-ximpact-powerbi">
    <PageHeader
      :icon="$t('IMPACT.ICONS.IFRAME')"
      :title="$t('IMPACT.TITLES.HEADER_POWER_BI')"
      class="pt-2"
    >
    </PageHeader>
    <iframe
      id="powerbi-iframe"
      title="impact_bi"
      class="page-ximpact-powerbi__iframe-page"
      style="display: inline;"
      width="auto"
      height="100%"
      frameborder="0"
      allowfullscreen="true"
      src="https://app.powerbi.com/view?r=eyJrIjoiYjcwMGNiZjAtMGFmMy00ZjE5LTgxYzktNWE0ZjJhMmYzZTMzIiwidCI6IjFiYmE3YWM2LTBjODYtNDQyNi04ZTYyLTI2ZjQ1MjQwMWU2NyIsImMiOjl9&pageName=ReportSection7aadaae72056631b5fd5"
     />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  components: {
    PageHeader,
  },
}
</script>

<style lang="scss">
.page-ximpact-powerbi {
  background-color: $color-white;
}

.page-ximpact-powerbi__iframe-page {
  margin: 20px 16px 0;
}
</style>